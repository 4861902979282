import React from 'react';
import Layout from '../../components/layout.js';
import { CommonContext } from '../../context.js';
import { PropTypes } from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  {
    file(relativePath: { eq: "bkash.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`;

const BkashPage = (props) => {
  const data = useStaticQuery(query);
  const bkashImage = data.file.childImageSharp.gatsbyImageData;

  return (
    <CommonContext.Provider value={{ location: props.location }}>
      <Layout title="Donate through Bkash" location={props.location} noindex>
        <section>
          <div className="flex justify-center">
            <div className="w-full max-w-6xl px-6 md:px-0">
              <div className="flex justify-center mt-0 mb-24 md:mt-8">
                <div className="w-full max-w-xl p-6 blog markdown md:p-0">
                  <h1>উত্তম সাদাকা দিন GreenTech Apps এ বিকাশের মাধ্যমে</h1>
                  <p>আসসালামু আলাইকুম,</p>
                  <p>
                    প্রিয় GTAF পরিবার, আলহামদুলিল্লাহ দীর্ঘ ৯ বছর ধরে আপনাদের
                    অপরিসীম সমর্থন পেয়ে আমরা আন্তরিকভাবে কৃতজ্ঞ।
                  </p>
                  <p>
                    আপনারা জেনে খুশি হবেন যে, বাংলাদেশে আমাদের এপ ব্যবহারকারীরা
                    এখন থেকে বিকাশের পেমেন্ট অপশনের মাধ্যমে আর্থিক অনুদান পাঠাতে
                    পারবেন যা সরাসরি Greentech Apps Foundation এর অধীনে থাকা
                    এপগুলোর পরিবর্ধন ও পরিমার্জনের কাজে ব্যবহার করা হবে ইন শা
                    আল্লাহ। আপনারা অনেকেই নিয়মিত অনুদান পাঠানোর জন্য আমাদের
                    কাছে আগ্রহ প্রকাশ করেছেন, যেটা এখন থেকে বিকাশের মাধ্যমে
                    সহজেই আপনারা করতে পারবেন।
                  </p>
                  <p>
                    বিকাশের মাধ্যমে পেমেন্ট অপশন থেকে নিচের মার্চেন্ট একাউন্টে
                    অনুদান পাঠাতে পারবেন।
                  </p>
                  <p>বিকাশ মার্চেন্ট একাউন্ট নাম্বারঃ 01914353593</p>
                  <p>
                    আপনার যেকোনো পরিমাণ অনুদান আমাদের মূল লক্ষ্য এবং উদ্দেশ্য
                    অর্জনে বিশদভাবে সহায়তা করবে।
                  </p>
                  <p>
                    এখন থেকে আপনিও নিয়মিত মাসিক অনুদানের মাধ্যমে আমাদের কাজে
                    অংশগ্রহণ করে GTAF-কে এগিয়ে নিয়ে যেতে প্রত্যক্ষ ভূমিকা পালন
                    করতে পারবেন এবং আপনার অনুদানের ফলে এপের ব্যবহারকারীরা একজন
                    মুসলিম হিসেবে নিজের জ্ঞানের উৎকর্ষ সাধনের মাধ্যমে লাভবান হতে
                    পারবে ইন শা আল্লাহ।
                  </p>
                  <GatsbyImage
                    image={bkashImage}
                    className="mx-auto text-center fill-current"
                    alt="bkash greentech"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </CommonContext.Provider>
  );
};

BkashPage.propTypes = {
  location: PropTypes.object,
};

export default BkashPage;
